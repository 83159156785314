import { defineStore } from 'pinia';
import http, { Response } from '@/utils/http';

type messageType = {
  [props: string]: unknown
  dotShow: boolean
}
export const useMessageStore = defineStore('message', {
  state: ():messageType => {
    return {
      dotShow: false,
    };
  },
  getters: {},
  actions: {
    async updateDotShow() {
      const result = await updateDotShow()
      if (result.code === 200) {
        this.dotShow = result.data
      }
    }
  },
});


const updateDotShow = () => {
  return http.get<Response<any>>('/api/new_gateway/manpower-cloudbp/message/checkHasRead', {roleType: 3});
}
