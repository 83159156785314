import {
  createRouter,
  createWebHashHistory,
  type RouteRecordRaw,
} from 'vue-router';
import { useUserStore } from '@/store';
import { useMessageStore } from '@/store/modules/message/index';

const BpHome = () => import('@/views/share/home.vue');
const BpInvitation = () => import('@/views/share/invitation.vue');
const BpPosters = () => import('@/views/share/posters.vue');
const Admin = () => import('@/views/layout/index.vue');
const Password = () => import('@/views/admin/password/index.vue');
const EditPassword = () => import('@/views/admin/password/editPassword.vue');
// const FindPassword = () => import('@/views/admin/password/findPassword.vue')
// 我的 -收藏
// const FindPassword = () => import('@/views/admin/password/findPassword.vue')
// 我的收藏
const MyCollection = () => import('@/views/user/collection.vue');
// 我的 -历史收益
const MyHistoricalYield = () => import('@/views/user/historicalYield.vue');
// 我的 -奖金政策
const MyBonusPolicy = () => import('@/views/user/bonusPolicy.vue');
//选择擅长职位
const Position = () => import('@/views/position/add.vue');
// 本月收益
const IncomeMonth = () => import('@/views/income/month.vue');

const routes: Array<RouteRecordRaw> = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'wxlogin',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/login/register.vue'),
    meta: {
      title: '注册',
    },
  },
  {
    name: 'findPassword',
    path: '/find-password',
    component: () => import('@/views/login/findPassword.vue'),
    meta: {
      title: '找回密码',
    },
  },
  {
    name: 'bindPhone',
    path: '/bindPhone',
    component: () => import('@/views/bindPhone/bindPhone.vue'),
    meta: {
      title: '绑定手机号',
    },
  },
  {
    name: 'positionDetails',
    path: '/position-details/:id',
    component: () => import('@/views/positionDetails/index.vue'),
    meta: {
      title: '职位详情',
    },
  },
  // 职位详情简短版
  {
    name: 'positionDetails',
    path: '/d/:id',
    component: () => import('@/views/positionDetails/index.vue'),
    meta: {
      title: '职位详情',
    },
  },
  {
    name: 'developerDetails',
    path: '/developer-details/:id',
    component: () => import('@/views/developerDetails/index.vue'),
    meta: {
      title: '候选人详情',
    },
  },
  {
    name: 'arrange',
    path: '/arrange',
    component: () => import('@/views/interview/arrange.vue'),
    meta: {
      title: '安排面试',
    },
  },
  {
    name: 'positionSearch',
    path: '/position-search',
    component: () => import('@/views/home/positionSearch.vue'),
    meta: {
      title: '职位搜索',
      keepAlive: true,
      freshen: false
    },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'positionDetails') {
        to.meta.freshen = true
      } else {
        to.meta.freshen = false
      }
      next()
    }
  },
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/layout/index.vue'),
    meta: {
      title: '首页',
    },
    children: [
      {
        name: 'home',
        path: '/home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '首页',
          keepAlive: true,
          freshen: false
        },
        beforeEnter: (to, from, next) => {
          console.log('首页');
          if (from.name !== 'positionDetails') {
            to.meta.freshen = true
          } else {
            to.meta.freshen = false
          }
          next()
        }
      },
      {
        name: 'workbench',
        path: '/workbench',
        component: () => import('@/views/workbench/index.vue'),
        meta: {
          title: '工作台',
        },
      },
      {
        name: 'information',
        path: '/information',
        component: () => import('@/views/information/information.vue'),
        meta: {
          title: '消息',
          keepAlive: true,
        },
      },
      {
        name: 'user',
        path: '/user',
        component: () => import('@/views/user/index.vue'),
        meta: {
          title: '我的',
        },
      },
    ],
  },
  // 本月收益
  {
    name: 'income-month',
    path: '/income-month',
    component: IncomeMonth,
    meta: {
      title: '本月收益',
    },
  },
  // 用户信息编辑页面
  {
    name: 'userInfo',
    path: '/user/editUserInfo',
    component: () => import('@/views/editUserInfo/index.vue'),
  },
  // 最近奖金政策
  {
    path: '/MyBonusPolicy',
    name: 'MyBonusPolicy',
    component: MyBonusPolicy,
    meta: {
      title: '最近奖金政策',
    },
  },
  // 历史收益
  {
    path: '/MyHistoricalYield',
    name: 'MyHistoricalYield',
    component: MyHistoricalYield,
    meta: {
      title: '历史收益',
    },
  },
  {
    name: 'auth',
    path: '/user/auth',
    component: () => import('@/views/auth/auth.vue'),
  },
  {
    name: 'selectCity',
    path: '/user/selectCity',
    component: () => import('@/views/selectCity/selectCity.vue'),
  },
  {
    name: 'selectIndustry',
    path: '/user/selectIndustry',
    meta: {
      title: '擅长行业',
    },
    component: () => import('@/views/selectIndustry/selectIndustry.vue'),
  },

  {
    path: '/myCollection',
    name: 'MyCollection',
    component: MyCollection,
    meta: {
      title: '收藏',
    },
  },

  {
    path: '/bp-home',
    name: 'BpHome',
    component: BpHome,
    meta: {
      title: '',
    },
  },
  {
    path: '/bp-invitation',
    name: 'BpInvitation',
    component: BpInvitation,
    meta: {
      title: '',
    },
  },
  {
    path: '/bp-posters',
    name: 'BpPosters',
    component: BpPosters,
    meta: {
      title: '',
    },
  },
  // admin-------
  {
    path: '/admin',
    redirect: '/admin/password',
    component: Admin,
    meta: {
      title: '密码',
    },
    children: [
      {
        path: '/admin/password',
        name: 'password',
        component: Password,
        meta: {
          title: '密码设置',
        },
      },
    ],
  },
  {
    path: '/admin/edit-password',
    name: 'edit-password',
    component: EditPassword,
    meta: {
      title: '修改密码',
    },
  },
  {
    path: '/user/select-account',
    name: 'selectAccount',
    component: () => import('@/views/selectAccount/index.vue'),
    meta: {
      title: '选择招聘账号',
    },
  },
  {
    path: '/position/add',
    name: 'position-add',
    component: Position,
    meta: {
      title: '擅长职位',
    },
  },
  {
    name: 'recommendDetails',
    path: '/recommned-details/:id',
    component: () => import('@/views/recommendDetails/index.vue'),
    meta: {
      title: '职位详情',
    },
  },
  {
    name: 'wxlogin',
    path: '/wxlogin',
    component: () => import('@/views/wxLogin/wxLogin.vue'),
  },
  {

    name: 'editCareerInfo',
    path: '/editCareer',
    component: () => import('@/views/editCareerInfo/index.vue'),
  },
  {
    name: 'editEducationInfo',
    path: '/editEducation',
    component: () => import('@/views/editEducationInfo/index.vue'),
  },
  {
    name: 'selectEditCareerIndustry',
    path: '/editCareer/selectIndustry',
    meta: {
      title: '擅长行业',
    },
    component: () => import('@/views/editCareerInfo/selectIndustry.vue'),
  },

{
    name: 'edit-resume',
    path: '/edit-resume/:id',
    component: () => import('@/views/editResume/index.vue'),
    meta: {
      title: '编辑简历',
    },
  },
  // 编辑简历基本信息
  {
    name: 'editResumeUserInfo',
    path: '/editResumeUserInfo',
    component: () => import('@/views/editResumeUserInfo/editResumeUserInfo.vue'),
  },
  // 编辑简历-工作经历
  {
    name: 'editWork',
    path: '/editWork-details',
    component: () => import('@/views/editWorkPro/editWork.vue'),
  },
  // 编辑简历-项目经历
  {
    name: 'editProject',
    path: '/editProject-details',
    component: () => import('@/views/editWorkPro/editProject.vue'),
  },
  // 候选人列表
  {
    name: 'candates',
    path: '/candates/:id',
    meta: {
      title: '优质候选人',
      keepAlive: true,
    },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'developerDetails') {
        to.meta.freshen = true
      } else {
        to.meta.freshen = false
      }
      next()
    },
    component: () => import('@/views/candidates/index.vue')
  },
];

// 路由白名单
const routeWhiteList: string[] = ['login', 'register', 'findPassword', 'bindPhone','home','positionDetails','positionSearch','user', 'wxlogin'];
const router = createRouter({
  routes,
  history: createWebHashHistory(),
  scrollBehavior:(to,from,savePosition)=>{
    console.log(savePosition);
    return {
      top: 500
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const title = to?.meta?.title;
  // 不在白名单中的页面要校验token
  if (!routeWhiteList.includes(to.name as string)) {
    if (userStore.token) {
      // 用户信息不存在就是请求
      !userStore.personal && (await userStore.getPersonal());
      useMessageStore().updateDotShow();
    } else {
      // 判断是不是微信环境
      const ua = navigator.userAgent.toLowerCase();
      // @ts-ignore
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        next('wxlogin');
      } else {
        window.location.href = `${process.env.VITE_APP_ACCOUNT_URL}/#/login/phone?loginChannel=cloud_bp&redirect=${process.env.VITE_URL}`
        // next('login');
      }

    }
  }
  if (title) {
    document.title = title as string;
  }
  next();
});

export default router;
