import http, { Response } from '@/utils/http';
import type {LoginParams, TokenInfo, BpPersonal, ResetPasswordType, UpdateBpPersonalParams} from './types'
export * from './types';

export default {
  // 登录
  async login(data: LoginParams) {
    return await http.post<Response<TokenInfo>>('/api/new_gateway/manpower-cloudbp/oauth/token', data, {
      headers: {
        "Authorization": 'Basic dG50bGlua2luZzptYW5wb3dlcg==',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        noToken: true
      },
    });
  },
  async sendCode(params:{mobile: string}) {
    return await http.get<Response>('/api/new_gateway/manpower-cloudbp/sms/send', params, {
      headers: {
        noToken: true
      }
    });
  },
  async getBpPersonal(params?: any) {
    return await http.get<Response<BpPersonal>>('/api/new_gateway/manpower-cloudbp/bp/getBpPersonal',params)
  },
  async resetPassword(data: ResetPasswordType) {
    return await http.post<Response<any>>('/api/new_gateway/manpower-cloudbp/sms/resetPassword',data)
  },
  async updateBpPersonal(data: UpdateBpPersonalParams) {
    return await http.post<Response<any>>('/api/new_gateway/manpower-cloudbp/bp/updateBpPersonal',data)
  },

  // 获取云bp历史收益
  // https://talent-operation.staging.tntlinking.com/api/new_gateway/manpower-cloudbp/doc.html#/default/BP%E8%A7%92%E8%89%B2%20%E4%B8%93%E7%94%A8%E6%8E%A5%E5%8F%A3/getBpRevenueTotalUsingGET
  async getBpRevenueTotal(){
    return await http.get<Response<any>>('/api/new_gateway/manpower-cloudbp/bp/getBpRevenueTotal');
  },

  // 云bp奖励政策
  async getPageContentPageById(data?: any){
    return await http.get<Response<any>>('/api/new_gateway/manpower-cloudbp/pageContent/page/'+data.id,data);
  }
};
