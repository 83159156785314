<template>
  <div id="app">
    <router-view v-slot="{ Component, route }">
      <KeepAlive>
        <component :is="Component" :key="route.title" v-if="route.meta.keepAlive" />
      </KeepAlive>
      <component :is="Component" v-if="!route.meta.keepAlive" />
    </router-view>
  </div>
</template>

<script>

</script>

<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>
