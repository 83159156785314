import { createApp } from 'vue'
import { allowMultipleToast } from 'vant';
import App from './App.vue'
import store from '@/store'
import 'vant/es/toast/style'
import 'vant/es/notify/style'
import 'vant/es/dialog/style'
import router from './router'
import '@/styles/index.scss'
import Vconsole from 'vconsole';

if (process.env.VITE_BUILD_VCONSOLE === "true") {
  const vConsole = new Vconsole()
}
console.log(window)
allowMultipleToast();
const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
