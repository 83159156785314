import axios, { AxiosResponse, AxiosRequestConfig, AxiosError, InternalAxiosRequestConfig } from 'axios';
import type { Response } from './types';
import { showFailToast } from 'vant';
import router from '@/router';
import { useUserStore } from '@/store';

axios.defaults.timeout = 1000 * 60;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// 创建axios实例
const service = axios.create({
  // 根据不同env设置不同的baseURL
  // baseURL: static.env.config.js.VITE_APP_API_BASE_URL,
});

// axios实例拦截请求
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (!config.headers.noToken) {
      const userStore = useUserStore();
      Object.assign(config.headers, {
        Authorization: userStore.token
      })
    }
    delete config.headers.noToken
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);
// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse<Response>) => {
    const { code, message } = response.data;
    let errMessage = '';
    switch (code) {
      case 200:
        break;
      case 401: // token过期
        errMessage = '暂未登陆';
        const userStore = useUserStore();
        userStore.removeToken()
        const ua = navigator.userAgent.toLowerCase();
        let path = location.hash.slice(1)
        // @ts-ignore
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          router.push({
            path: '/wxlogin', query: {
              redirectUrl: path
          }});
        } else {
          window.location.href = `${process.env.VITE_APP_ACCOUNT_URL}/#/login/phone?loginChannel=cloud_bp&redirect=${process.env.VITE_URL}`
        }
        break;
      default:
        errMessage = message;
        break;
    }
    if (errMessage && code !== 447) showFailToast(errMessage);
    return response;
  },
  // 非2xx时触发
  (error: AxiosError) => {
    showFailToast('Network Error...');
    return Promise.reject(error);
  }
);

export type { AxiosResponse, AxiosRequestConfig };

export default service;
