import { defineStore } from 'pinia';
import User, { TokenInfo, BpPersonal } from '@/api/user';
// @ts-ignore
import cookie from 'js-cookie';
type userType = {
  [props: string]: unknown
  tokenInfo: TokenInfo | null,
  access_token: string | null,
  personal:BpPersonal | null
}
export const useUserStore = defineStore('user', {
  state: ():userType => {
    return {
      tokenInfo: null,
      access_token: cookie.get('accessToken'),
      personal: null
    };
  },
  getters: {
    token: (state) => {
      return state.access_token ? "Bearer " + state.access_token : state.access_token
    }
  },
  actions: {
    async getPersonal() {
      const { code, data } = await User.getBpPersonal()
      if (code === 200) {
        this.personal = data
      }
    },
    updateTokenInfo(data:TokenInfo | null) {
      this.tokenInfo = data
    },
    updateToken(token: string) {
      this.access_token = token
      // window.localStorage.setItem('access_token',token)
      cookie.set('accessToken', token)
    },
    removeToken() {
      this.access_token = ''
      this.personal = null
      cookie.remove('accessToken', { path: '/', domain: 'localhost'})
      cookie.remove('accessToken',{path:'/', domain: '.tntlinking.com'})
    }
  },
});
