import { defineStore } from 'pinia';

type postionType = {
  [props: string]: unknown
  searchHistory: string[]
}
export const usePostionStore = defineStore('position', {
  state: ():postionType => {
    return {
      searchHistory: JSON.parse(localStorage.getItem('searchHistory') || '[]')
    };
  },
  getters: {},
  actions: {
    updateSearchHistory(data: string) {
      if (this.searchHistory.includes(data)) {
        return
      }
      this.searchHistory.push(data)
      window.localStorage.setItem('searchHistory',JSON.stringify(this.searchHistory))
    },
    clearSearchHistory() {
      this.searchHistory = []
      window.localStorage.setItem('searchHistory','')
    }
  },
});
