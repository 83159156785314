import { defineStore } from 'pinia';

type resumeType = {
  [props: string]: unknown
  parseResult: any,
  currEditDate: any
}
export const useResumeStore = defineStore('resume', {
  state: ():resumeType => {
    return {
      parseResult: JSON.parse(localStorage.getItem('parseResult') || 'null'),
      currEditDate: JSON.parse(localStorage.getItem('currEditDate') || 'null'),
      params: {}
    };
  },
  getters: {},
  actions: {
    updatedParseResult(data: any) {
      this.parseResult = data
      window.localStorage.setItem('parseResult', JSON.stringify(data))
    },
    updateEditDate(data: any) {
      this.currEditDate = data
      window.localStorage.setItem('currEditDate', JSON.stringify(data))
    }
  },
});
